import React from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, useCycle } from "framer-motion";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import NavbarLogo from "./NavbarLogo";

import { HiOutlineMail } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";

import "./style.menu.scss";
import Helmetor from "../_utils/Helmetor";

const divVariants = {
  open: ({ x, y, w, h }) => ({
    display: "flex",
    //  opacity: 1,
    clipPath: `circle(2500px at ${x + w / 2}px ${y + h / 2}px)`,
    transition: {
      type: "tween",
      delayChildren: 0.2,
      when: "beforeChildren",
      duration: 0.3,
      ease: "circIn",
    },
  }),
  closed: ({ x, y, w, h }) => ({
    // opacity: 0,
    clipPath: `circle(70px at ${x + w / 2}px ${y + h / 2}px)`,
    transition: {
      // delay: 0.2,
      // when: "beforeChildren",
      duration: 0.3,
      type: "tween",
    },
    transitionEnd: { display: "none" },
  }),
};

const ulVariants = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
  },
  closed: {
    transition: { duration: 0.2 },
  },
};

const menuVariants = {
  open: {
    //y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      type: "tween",
      ease: "easeOut",
    },
  },
  closed: {
    // y: 1000,
    scale: 0,
    opacity: 0,
    //display: "none",
  },
};

const Navbar = ({ params }) => {
  const [toggleMenu, setToggleMenu] = useCycle(false, true);
  const [positions, setPositions] = React.useState({ x: 0, y: 0 });
  const menuButton = React.useRef();
  const { pathname } = useLocation();

  let theme = pathname.replace(/\//g, "") || "default";
  // theme += seasonId && "s" + seasonId;
  // theme += episodeId && "e" + episodeId;

  // X

  React.useEffect(() => {
    const { x, y, width, height } = menuButton.current.getBoundingClientRect();

    setPositions({ x, y, w: width, h: height });
  }, [toggleMenu, menuButton]);

  return (
    <>
      <nav>
        <NavbarLogo lang={params.cultureKey} />
        {/** MENU BUTTON */}
        <Container
          className="fixed-top max-w d-flex justify-content-end pe-0"
          style={{ zIndex: 2010 }}>
          {!toggleMenu && (
            <motion.div
              className="menu-button-shadow position-absolute m-3 px-1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            />
          )}
          <div
            className="menu-button-wrapper position-absolute"
            ref={menuButton}>
            <MenuToggle toggle={setToggleMenu} isOpen={toggleMenu} />
          </div>
        </Container>

        {/** MENU CONTENT */}

        <motion.div
          className="fixed-top vw-100 vh-100 text-center bg-white"
          initial={false}
          animate={toggleMenu ? "open" : "closed"}
          variants={divVariants}
          custom={positions}
          style={{ originX: 0.5, zIndex: 2000, overflowY: "scroll" }}>
          <div className="d-flex flex-column justify-content-between vh-100">
            {toggleMenu ? (
              <Helmetor color="menutop" />
            ) : (
              <Helmetor color={theme} />
            )}
            <MenuSeasons {...{ toggleMenu, setToggleMenu, params }} />
            <MenuMain {...{ toggleMenu, setToggleMenu, params }} />
            <MenuContact {...{ toggleMenu, setToggleMenu, params }} />
            <MenuLang {...{ toggleMenu, setToggleMenu, params }} />
          </div>
        </motion.div>
      </nav>
    </>
  );
};

const MenuItem = ({ text, to, handleClick }) => {
  return (
    <motion.li variants={menuVariants} style={{ paddingBottom: "1vh" }}>
      <motion.div whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.1 }}>
        <Link className="menu-main-btn" onClick={handleClick} to={to}>
          {text}
        </Link>
      </motion.div>
    </motion.li>
  );
};
const MenuContactItem = ({ icon, to, handleClick }) => {
  return (
    <motion.li variants={menuVariants}>
      <motion.div whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.1 }}>
        {/* Pas terrible mais ca fonctionne */}
        {icon === "instagram" ? (
          <a
            className="text-decoration-none text-reset"
            href={to}
            onClick={handleClick}
            target="_blank"
            rel="noreferrer">
            <FaInstagram className="fa-2x" />
          </a>
        ) : (
          <Link
            className="text-decoration-none text-reset"
            onClick={handleClick}
            to={to}>
            {icon === "contact" && <HiOutlineMail className="fa-2x" />}
            {icon === "licence" && <span></span>}
            {icon === "lang" && <span></span>}
          </Link>
        )}
      </motion.div>
    </motion.li>
  );
};
const MenuSeasonsItem = ({ text, to, lang, handleClick }) => {
  return (
    <motion.li variants={menuVariants}>
      <motion.div whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.12 }}>
        <Link
          className="text-reset text-decoration-none"
          onClick={handleClick}
          to={to}>
          {" "}
          <img
            src={
              process.env.REACT_APP_BASE_URL +
              "assets/stories/" +
              lang +
              to +
              "/cover.jpg"
            }
            alt="alt"
            width="100%"
            className="rounded-1"
          />
          {text}
        </Link>
      </motion.div>
    </motion.li>
  );
};

const MenuSeasons = ({ toggleMenu, setToggleMenu, params }) => {
  return (
    <motion.div
      className="vw-100 menu-season-superstern"
      animate={toggleMenu ? "open" : "closed"}
      variants={{
        open: { y: 0, transition: { delay: 0.2, ease: "circOut" } },
        closed: { y: "-50vh" },
      }}>
      <Container className="max-w-sm pt-3">
        <h2 className="h4">{params.lexicon.menuseasons}</h2>

        <motion.ul
          animate={toggleMenu ? "open" : "closed"}
          variants={ulVariants}
          className="d-flex justify-content-center gap-3 list-unstyled px-md-5 pb-5">
          {params.menu.seasons.map((item, idx) => (
            <MenuSeasonsItem
              key={idx}
              handleClick={() => setToggleMenu()}
              lang={params.cultureKey}
              to={item.url}
              text={item.text}
              // {...{ ...item }}
            />
          ))}
        </motion.ul>
      </Container>
    </motion.div>
  );
};
const MenuMain = ({ toggleMenu, setToggleMenu, params }) => {
  return (
    <Container className="max-w-sm">
      <motion.ul
        animate={toggleMenu ? "open" : "closed"}
        variants={ulVariants}
        className="d-flex flex-column gap-3 fs-4 list-unstyled">
        {params.menu.main.map((item, idx) => (
          <MenuItem
            key={idx}
            handleClick={() => setToggleMenu()}
            text={item.text}
            to={item.url}
          />
        ))}
      </motion.ul>
    </Container>
  );
};
// eslint-disable-next-line no-unused-vars
const MenuContact = ({ toggleMenu, setToggleMenu, params }) => {
  const { pathname } = useLocation();
  const languages = [
    ["Deutsch", "de"],
    ["Français", "fr"],
    ["Italiano", "it"],
    ["Rumantsch", "rm"],
    ["English", "en"],
    ["Romanian", "ro"],
  ];

  return (
    <motion.ul
      animate={toggleMenu ? "open" : "closed"}
      variants={ulVariants}
      className="d-flex justify-content-center gap-3 fs-4 list-unstyled">
      {params.menu.additional.map((item, idx) => (
        <MenuContactItem
          key={idx}
          handleClick={() => setToggleMenu()}
          icon={item.icon}
          to={item.url}
        />
      ))}
      <li className="d-flex align-items-center border-start ps-3">
        <MdLanguage className="fa-2x" />
        <select
          className="form-select ms-2"
          aria-label="Default select example"
          onChange={(e) =>
            (window.location.href = `/${e.target.value}${pathname}`)
          }>
          {languages.map(([name, code]) => {
            return (
              <option
                key={`langcode_${code}`}
                value={code}
                selected={params.cultureKey === code}>
                {name}
              </option>
            );
          })}
        </select>
      </li>
    </motion.ul>
  );
};
const MenuLang = ({ toggleMenu, setToggleMenu, params }) => {
  // const _lang = "de,fr,it,en,rm,ro";
  // const cLang = params.cultureKey;
  console.log("Navigator", navigator);
  return <div style={{ height: "1vh" }}></div>;
  // return (
  //   <motion.ul
  //     animate={toggleMenu ? "open" : "closed"}
  //     variants={ulVariants}
  //     className="d-flex justify-content-center gap-4 fs-5 list-unstyled">
  //     {_lang.split(",").map((lg, idx) => (
  //       <li key={"lang" + lg + idx}>
  //         <a
  //           className={cLang === lg ? "text-reset text-decoration-none" : ""}
  //           href={"/" + lg}>
  //           {lg}
  //         </a>
  //       </li>
  //     ))}
  //   </motion.ul>
  // );
};

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="4"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle, isOpen }) => (
  <motion.div whileTap={{ scale: 0.9 }}>
    <Button
      className="m-3 shadow-none bg-transparent border-0 text-dark"
      onClick={toggle}>
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path
          animate={isOpen ? "open" : "closed"}
          d="M 2 2.5 L 20 2.5"
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          animate={isOpen ? "open" : "closed"}
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          d="M 2 16.346 L 20 16.346"
          animate={isOpen ? "open" : "closed"}
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </svg>
    </Button>
  </motion.div>
);

export default Navbar;
